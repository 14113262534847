import {each} from 'lodash'
import autoComplete from "@tarekraafat/autocomplete.js"
import {x, x0} from '../_helpers'


export default class InputList {

    constructor() {
        this.element = x0('[js-input-list]')
        this.index = 1;
        this.maxLines = 20

        if (this.element) {
            this.list = x0('.m-input-list__list', this.element);
            this.data = this.element.getAttribute('js-input-list');
            this.init()
        }
    }

    initAutoComplete(trigger) {
        new autoComplete({
            data: {
                src: JSON.parse(this.data),
                keys: ["label"]
            },
            sort: (a, b) => {                    // Sort rendered results ascendingly | (Optional)
                if (a.match < b.match) return -1;
                if (a.match > b.match) return 1;
                return 0;
            },
            selector: () => trigger,
            observer: true,                      // Input field observer | (Optional)
            threshold: 1,                        // Min. Chars length to start Engine | (Optional)
            searchEngine: "strict",              // Search Engine type/mode           | (Optional)
            //highlight: true,                     // Highlight matching results      | (Optional)
            resultsList: {
                maxResults: 20,                  // Max. number of rendered results | (Optional)
            },
            resultItem: {
                tag: "li",
                class: "autoComplete_result",
                highlight: "autoComplete_highlight",
                selected: "autoComplete_selected"
            },
            events: {
                input: {
                    selection: (event) => {
                        trigger.value = event.detail.selection.value.label;

                        const id = x0('.m-input-list__input-id', trigger.closest('.m-input-list__input'))
                        if (id) {
                            id.value = event.detail.selection.value.id;
                        }
                    },
                },
            },
        });
    }

    init() {
        const autocomplete = x('[js-input-list-line] [js-input-list-input]', this.element);
        const removeTriggers = x('[js-input-list-line] [js-input-list-remove]', this.element);
        const line = x0('.m-input-list__input.-template');
        const addTrigger = x0('[js-input-list-add]');

        this.index = autocomplete.length > 1 ? autocomplete.length : 1

        if(this.index >= this.maxLines) {
            this.element.classList.add('-max-input')
        }

        each(autocomplete, trigger => {
            this.initAutoComplete(trigger);
        });

        each(removeTriggers, trigger => {
            trigger.addEventListener('click', () => {
                this.removeLine(trigger.closest('.m-input-list__input'))
            });
        });

        addTrigger.addEventListener('click', () => {
            if (this.index < this.maxLines) {
                this.index++;

                if (this.index >= this.maxLines) {
                    this.element.classList.add('-max-input')
                }

                const newLine = line.cloneNode(true);
                const removeTrigger = x0('[js-input-list-remove]', newLine);

                this.list.appendChild(newLine)
                newLine.classList.remove('-template');

                each(x('input', newLine), el => {
                    el.required = true;
                });

                this.initAutoComplete(x0('[js-input-list-input]', newLine));

                this.fixInputNames()

                removeTrigger.addEventListener('click', () => {
                    this.removeLine(newLine)
                });
            }
        });
    }

    removeLine(line) {
        line.remove(line);

        this.index--;

        if (this.index < this.maxLines) {
            this.element.classList.remove('-max-input')
        }

        this.fixInputNames()
    }

    fixInputNames() {
        const lines = x('.m-input-list__input:not(.-template)', this.list)

        each(lines, (line, index) => {
            line.dataset.index = index
            each(x('input', line), el => {
                if (el.dataset.nameTemplate) {
                    el.name = el.dataset.nameTemplate.replace(':index', index)
                }
            });
        })
    }
}
